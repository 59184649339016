import { useQuery, useQueryClient } from '@tanstack/react-query';
import useApiClient from 'utils/apiClientHook';

type websocketResponse = {
  code: string;
  organizationId: string;
};

export const useWebsocketOneTimeCode = () => {
  const { computeClient: client } = useApiClient();
  return useQuery(['websocket'], async () => {
    const response = await client.get<websocketResponse>(`/websocket`);
    const code = response.data.code;
    const orgId = response.data.organizationId;
    return `${process.env.REACT_APP_WEBSOCKET_URL!}?code=${code}&organizationId=${orgId}`;
  });
};

export const useRefreshWebsocketAuth = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(['websocket']);
};

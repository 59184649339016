import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useMemo } from 'react';
import { useToast } from './toast';
import throttle from 'lodash/throttle';

export function QueryClientWrapper({ children }) {
  const { toastError: _toastError } = useToast();

  const throttledToastError = useMemo(() => throttle(_toastError, 10000), [_toastError]);

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: (failureCount, error: any) => {
              if ((error as AxiosError)?.response?.status === 401) {
                throttledToastError('Not authenticated.', 'Please sign in again.');
                return false;
              }
              if ((error as AxiosError)?.response?.status === 412) {
                throttledToastError('User setup error', 'Ensure the administrator has assigned your organization.');
                return false;
              }
              return failureCount < 3;
            },
          },
        },
      }),
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type DictationDefinition = NewDictationDefinitionRequest &
  Auditable & {
    templateHeaders: TemplateHeader[];
  };

export type TemplateHeader = {
  value: string;
  children: TemplateHeader[];
};

export type NewDictationDefinitionRequest = {
  maxDurationMinutes: number;
  template: string;
  appointmentTypeIds: string[];
  name?: string;
};

export const useGetDictationDefinitions = () => {
  const { computeClient: client } = useApiClient();
  return useQuery(['dictation-definition'], () =>
    client.get<DictationDefinition[]>('/dictation-definition').then(response => response.data),
  );
};

export const useGetDictationDefinitionsByAppointmentTypeId = (appointmentTypeId: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['dictation-definition', 'appointmentTypeId', appointmentTypeId], () =>
    client
      .get<DictationDefinition[]>(`/dictation-definition/appointmentTypeId/${appointmentTypeId}`)
      .then(response => response.data),
  );
};

export const useGetDictationDefinition = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['dictation-definition', id], () =>
    client.get<DictationDefinition>(`/dictation-definition/${id}`).then(response => response.data),
  );
};

export const useCreateDictationDefinition = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<DictationDefinition, AxiosError, NewDictationDefinitionRequest>({
    mutationFn: async function (request: NewDictationDefinitionRequest) {
      const response = await client.post('/dictation-definition', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['dictation-definition']);
    },
  });
};

export const useUpdateDictationDefinition = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<DictationDefinition, AxiosError, DictationDefinition>({
    mutationFn: request => client.put('/dictation-definition', request).then(response => response.data),
    onSuccess: async () => {
      await queryClient.refetchQueries(['dictation-definition']);
    },
  });
};

export const useDeleteDictationDefinition = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/dictation-definition/${id}`).then(response => response.data),
    onSuccess: async () => {
      await queryClient.refetchQueries(['dictation-definition']);
    },
  });
};

import { Appointment } from 'app/api/AppointmentApi';
import { TemporaryNote } from 'app/api/TemporaryNoteApi';
import { createContext, useContext } from 'react';
import React from 'react';
import { Patient } from '../app/api/PatientApi';

export interface ILocalStorageContext {
  getBearerToken: () => string | null;
  setBearerToken: (token: string) => void;
  getRefreshToken: () => string | null;
  setRefreshToken: (token: string) => void;
  destroyAuthTokens: () => void;
  setCopyPasteAppointment: (appointment: Appointment, patient: Patient) => void;
  getCopyPasteAppointment: () => { appointment: Appointment; patient: Patient } | null;
  setCopyPasteTemporaryNote: (note: TemporaryNote) => void;
  getCopyPasteTemporaryNote: () => TemporaryNote | null;
}

const LocalStorageContext = createContext<ILocalStorageContext>({
  getBearerToken: () => null,
  setBearerToken: () => {},
  getRefreshToken: () => null,
  setRefreshToken: () => {},
  destroyAuthTokens: () => {},
  setCopyPasteAppointment: () => {},
  getCopyPasteAppointment: () => null,
  setCopyPasteTemporaryNote: () => {},
  getCopyPasteTemporaryNote: () => null,
});

export function LocalStorageProvider({ children }) {
  const getBearerToken = () => localStorage.getItem('bearerToken');
  const setBearerToken = (token: string) => localStorage.setItem('bearerToken', token);
  const getRefreshToken = () => localStorage.getItem('refreshToken');
  const setRefreshToken = (token: string) => localStorage.setItem('refreshToken', token);

  const destroyAuthTokens = () => {
    localStorage.removeItem('bearerToken');
    localStorage.removeItem('refreshToken');
  };

  const setCopyPasteAppointment = (appointment: Appointment, patient: Patient) => {
    localStorage.setItem('copyPasteAppointment', JSON.stringify(appointment));
    localStorage.setItem('copyPastePatient', JSON.stringify(patient));
  };

  const getCopyPasteAppointment = () => {
    const item = localStorage.getItem('copyPasteAppointment');
    if (!item) {
      return null;
    }
    const patient = localStorage.getItem('copyPastePatient');
    if (!patient) {
      return null;
    }

    return { appointment: JSON.parse(item) as Appointment, patient: JSON.parse(patient) };
  };

  const setCopyPasteTemporaryNote = (note: TemporaryNote) => {
    localStorage.setItem('copyPasteTemporaryNote', JSON.stringify(note));
  };

  const getCopyPasteTemporaryNote = () => {
    const item = localStorage.getItem('copyPasteTemporaryNote');
    if (!item) {
      return null;
    }
    return JSON.parse(item) as TemporaryNote;
  };

  return (
    <LocalStorageContext.Provider
      value={{
        getBearerToken,
        setBearerToken,
        getRefreshToken,
        setRefreshToken,
        destroyAuthTokens,
        setCopyPasteAppointment,
        getCopyPasteAppointment,
        setCopyPasteTemporaryNote,
        getCopyPasteTemporaryNote,
      }}
    >
      <>{children}</>
    </LocalStorageContext.Provider>
  );
}

export const useLocalStorage = () => {
  return useContext(LocalStorageContext);
};

import axios, { AxiosInstance } from 'axios';
import { useAuth } from './auth';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export type ComputeClient = {
  computeClient: AxiosInstance;
};

function addAuth(bearerToken: () => string | null) {
  return request => {
    if (request && request.headers) {
      request.headers = JSON.parse(JSON.stringify(request.headers || {}));
      request.headers['Authorization'] = 'Bearer ' + bearerToken();
      request.headers['Content-Type'] = 'application/json';
    }
    return request;
  };
}

export default function useApiClient() {
  const { refreshBearerToken, getBearerToken } = useAuth();

  const computeClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_BACKEND_API_VERSION}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Separate clients for users because there is no way to create an aws vpc endpoint for cognito idp
  const userClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/v1`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  computeClient.interceptors.request.use(addAuth(getBearerToken));
  userClient.interceptors.request.use(addAuth(getBearerToken));

  const refreshAuthLogic = async failedRequest => {
    const token = await refreshBearerToken();
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + token;
    return Promise.resolve();
  };

  createAuthRefreshInterceptor(computeClient, refreshAuthLogic, {
    shouldRefresh: error => {
      if (error?.response?.status !== 401) {
        return false;
      }
      // Don't retry OPTIONS requests (CORS preflight)
      return error?.config?.method?.toLocaleLowerCase() !== 'options';
    },
    interceptNetworkError: true,
  });

  createAuthRefreshInterceptor(userClient, refreshAuthLogic, {
    shouldRefresh: error => {
      if (error?.response?.status !== 401) {
        return false;
      }
      // Don't retry OPTIONS requests (CORS preflight)
      if (error?.config?.method?.toLocaleLowerCase() === 'options') {
        return false;
      }
      return true;
    },
    interceptNetworkError: true,
  });

  return { computeClient, userClient };
}

import { InitialAppointmentScheduleQueueTask, useInvalidateInitialApptTasks } from 'app/api/ScheduleQueueApi';
import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Appointment, useInvalidateAppointments } from 'app/api/AppointmentApi';
import { TimeSlotOverflowApproval, useInvalidateTimeSlotOverflowApprovals } from 'app/api/TimeSlotOverflowApprovalApi';
import { useWebsocketWrapper } from './websocket-wrapper';
import { TemporaryNote, useInvalidateTemporaryNote } from 'app/api/TemporaryNoteApi';

export interface IWebsocketContext {
  isConnecting: boolean;
  isOpen: boolean;
  isClosing: boolean;
  isClosed: boolean;
  isUninstantiated: boolean;
  connectionStatus: string;
  lastAppointmentTaskMessage: ModelAction<InitialAppointmentScheduleQueueTask> | null;
  lastAppointmentMessage: ModelAction<Appointment> | null;
  lastTimeSlotOverflowApprovalMessage: ModelAction<TimeSlotOverflowApproval> | null;
  lastTemporaryNoteMessage: ModelAction<TemporaryNote> | null;
}

const WebsocketContext = createContext<IWebsocketContext>({
  isConnecting: false,
  isOpen: false,
  isClosing: false,
  isClosed: false,
  isUninstantiated: true,
  connectionStatus: 'Uninstantiated',
  lastAppointmentTaskMessage: null,
  lastAppointmentMessage: null,
  lastTimeSlotOverflowApprovalMessage: null,
  lastTemporaryNoteMessage: null,
});

type Identifiable = { id: string };

export type ModelAction<T extends Identifiable> =
  | {
      action: 'create' | 'update';
      timestamp: string;
      model: T;
    }
  | {
      action: 'delete';
      timestamp: string;
      model: { id: string };
    }
  | {
      action: 'unknown';
    };

type PushEvent<T extends Identifiable> = {
  topic: string;
  subject: string;
  timestamp: string;
  message: T;
};

class ModelActionFactory<T extends Identifiable> {
  Build(event: PushEvent<T>): ModelAction<T> {
    if (event.subject.includes('Created')) {
      return {
        action: 'create',
        timestamp: event.timestamp,
        model: event.message,
      };
    }
    if (event.subject.includes('Updated')) {
      return {
        action: 'update',
        timestamp: event.timestamp,
        model: event.message,
      };
    }
    if (event.subject.includes('Deleted')) {
      return {
        action: 'delete',
        timestamp: event.timestamp,
        model: { id: event.message.id },
      };
    }
    return {
      action: 'unknown',
    };
  }
}

export function WebsocketProvider({ children }) {
  const { websocketUrl, setError } = useWebsocketWrapper();

  const [lastAppointmentTaskMessage, setLastAppointmentTaskMessage] =
    useState<ModelAction<InitialAppointmentScheduleQueueTask> | null>(null);
  const [lastAppointmentMessage, setLastAppointmentMessage] = useState<ModelAction<Appointment> | null>(null);
  const [lastTimeSlotOverflowApprovalMessage, setLastTimeSlotOverflowApprovalMessage] =
    useState<ModelAction<TimeSlotOverflowApproval> | null>(null);
  const [lastTemporaryNoteMessage, setLastTemporaryNoteMessage] = useState<ModelAction<TemporaryNote> | null>(null);

  // Initial Appointment Queue
  const processAppointmentTask = (record: PushEvent<InitialAppointmentScheduleQueueTask>) => {
    const action = new ModelActionFactory<InitialAppointmentScheduleQueueTask>().Build(record);
    setLastAppointmentTaskMessage(action);
  };

  const processAppointment = (record: PushEvent<Appointment>) => {
    const action = new ModelActionFactory<Appointment>().Build(record);
    setLastAppointmentMessage(action);
  };

  const processTemporaryNote = (record: PushEvent<TemporaryNote>) => {
    const action = new ModelActionFactory<TemporaryNote>().Build(record);
    setLastTemporaryNoteMessage(action);
  };

  const processTimeSlotOverflow = (record: PushEvent<TimeSlotOverflowApproval>) => {
    const action = new ModelActionFactory<TimeSlotOverflowApproval>().Build(record);
    setLastTimeSlotOverflowApprovalMessage(action);
  };

  const invalidateAppointments = useInvalidateAppointments();
  const invalidateInitialApptTasks = useInvalidateInitialApptTasks();
  const invalidateTimeSlotOverflowApprovals = useInvalidateTimeSlotOverflowApprovals();
  const invalidateTemporaryNotes = useInvalidateTemporaryNote();

  const { lastMessage, readyState } = useWebSocket(websocketUrl, {
    share: true,
    shouldReconnect: e => true,
    reconnectAttempts: 5,
    reconnectInterval: 8000,
    onError: e => {
      console.error('error at websocket connection, reconnecting...', websocketUrl);
      setError(e);
    },
  });

  useEffect(() => {
    if (isOpen) {
      console.log('websocket connection established', websocketUrl);
      setTimeout(() => {
        invalidateAppointments();
        invalidateInitialApptTasks();
        invalidateTimeSlotOverflowApprovals();
        invalidateTemporaryNotes();
      }, Math.random() * 20000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState]);

  useEffect(() => {
    if (lastMessage !== null) {
      const record = JSON.parse(lastMessage.data);

      if (record.topic === 'appointment-queue-events') {
        processAppointmentTask(record);
        return;
      } else if (record.topic === 'appointment-events') {
        processAppointment(record);
        return;
      } else if (record.topic === 'temporary-note-events') {
        processTemporaryNote(record);
        return;
      } else if (record.topic === 'time-slot-overflow-request-events') {
        processTimeSlotOverflow(record);
        return;
      } else if (record.message === 'ping') {
        console.log('websocket ping received');
        return;
      }

      console.error('unknown topic', record);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const isConnecting = readyState === ReadyState.CONNECTING;
  const isOpen = readyState === ReadyState.OPEN;
  const isClosing = readyState === ReadyState.CLOSING;
  const isClosed = readyState === ReadyState.CLOSED;
  const isUninstantiated = readyState === ReadyState.UNINSTANTIATED;

  return (
    <WebsocketContext.Provider
      value={{
        isConnecting,
        isOpen,
        isClosing,
        isClosed,
        isUninstantiated,
        connectionStatus,
        lastAppointmentTaskMessage,
        lastAppointmentMessage,
        lastTimeSlotOverflowApprovalMessage,
        lastTemporaryNoteMessage,
      }}
    >
      <>{children}</>
    </WebsocketContext.Provider>
  );
}

export const useWebsockets = () => {
  return useContext(WebsocketContext);
};

import { useState, createContext, useContext, useEffect } from 'react';
import React from 'react';
import { User, useAuth } from './auth';

export interface IProfile {
  groups: string[];
  permissions: string[];
  name: string | null;
  username: string | null;
  email: string | null;
  subject: string | null; // sub. user id.
  capabilities: ICapabilities | null;
}

export class Profile implements IProfile {
  public groups: string[] = [];
  public permissions: string[] = [];
  public name: string | null = null;
  public username: string | null = null;
  public email: string | null = null;
  public subject: string | null = null;
  public capabilities: ICapabilities = new Capabilities();

  constructor();
  constructor(user: User);
  public constructor(...args: any[]) {
    if (args.length === 0) {
      return;
    }
    const user = args[0] as User;

    this.name = user.name;
    this.username = user.username;
    this.email = user.email;
    this.subject = user.sub;
    this.groups = user.groups;
    this.permissions = user.permissions;
    this.capabilities = new Capabilities(user.groups, user.permissions);
  }
}

export interface ICapabilities {
  canManageUsers: () => boolean;
  canViewSchedule: () => boolean;
  canViewPatientDirectory: () => boolean;
  canViewReports: () => boolean;
  canViewScheduleReports: () => boolean;
  canManageConfiguration: () => boolean;
  canViewInitialScheduleAppointmentQueue: () => boolean;
  canToggleRightClick: () => boolean;
  canViewKitchenSink: () => boolean;
  canApproveTimeslotOverflowRequests: () => boolean;
  canViewSmsConfig: () => boolean;
  canDeletePatients: () => boolean;
  canViewNewPdfButton: () => boolean;
  canSendBulkSms: () => boolean;
  canViewAppointmentDetail: () => boolean;
  canViewDictationConfig: () => boolean;
}

class Capabilities implements ICapabilities {
  constructor(private groups: string[] = [], private permissions: string[] = []) {
    this.groups = groups;
    this.permissions = permissions;
  }

  private userHasPermission = (permissionName: string): boolean => this.permissions.includes(permissionName);
  canViewSchedule = () => this.userHasPermission('schedule:read');
  canViewInitialScheduleAppointmentQueue = () => this.userHasPermission('schedule:read');

  canManageConfiguration = () => this.userHasPermission('schedule-config:write');

  canApproveTimeslotOverflowRequests = () => this.userHasPermission('schedule-overflow:write');

  canViewPatientDirectory = () => this.userHasPermission('patients:read');

  canManageUsers = () => this.userHasPermission('users:write');

  canToggleRightClick = () => this.userHasPermission('sausage-making:read');
  canViewKitchenSink = () => this.userHasPermission('sausage-making:read');
  canViewNewPdfButton = () => this.userHasPermission('sausage-making:read');

  canViewSmsConfig = () => this.userHasPermission('sms-config:write');
  canDeletePatients = () => this.userHasPermission('patients:delete');

  canViewScheduleReports = () => this.userHasPermission('reports:read');

  // Today we have only schedule reports, but we can add more reports in the future
  canViewReports = () => this.canViewScheduleReports();

  canSendBulkSms = () => this.userHasPermission('schedule-config:write');

  canViewAppointmentDictation = () => this.userHasPermission('dictation:read');

  // Today we have only dictation, but we can add more in the future
  canViewAppointmentDetail = () => this.canViewAppointmentDictation();

  canViewDictationConfig = () => this.userHasPermission('dictation-definition:write');
}

const ProfileContext = createContext<Profile>(new Profile());

export function ProfileProvider({ children }) {
  const auth = useAuth();

  const [profile, setProfile] = useState<Profile>(new Profile());

  useEffect(() => {
    if (!auth.user) {
      setProfile(new Profile());
      return;
    }
    setProfile(new Profile(auth.user));
  }, [auth.user]);

  return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>;
}

export const useProfile = () => {
  return useContext(ProfileContext);
};
